<template>

</template>

<script>
export default {
    name: "ReferralCodeStore",
    data: () => ({

    }),
    beforeMount(){
        const referralCode = this.$route.params.code
        localStorage.setItem('referral_code', referralCode)
        this.$router.push('/')
    }
}
</script>